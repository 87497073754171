<template>
  <div class="room-body-header d-f ai-c jc-sb">
    <div class="c-p" @click="$router.push('/housemaster')">
      &lt;{{ "返回上一页" }}
    </div>
    <div>
      <span class="num">房间总数：{{ roomNum }}</span>
      <span class="num">宿管名称：{{ masterName }}</span>
      <el-button type="primary" @click="$emit('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("housemaster");

export default {
  name: "room-body-header",
  components: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions(["searchTable", "batAdd", "saveRoomInfo"]),
  },
  watch: {},
  computed: {
    ...mapState(["sectionItem", "roomNum", "masterName"]),
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d7d8d9;
  .num {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 12px;
  }
  button {
    width: 88px;
  }
}
.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }
  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>