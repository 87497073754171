<template>
  <div class="my-collapse">
    <div class="header d-f ai-c" :style="'padding-left:' + (left || 0) + 'px'">
      <el-checkbox
        v-bind="$attrs"
        v-on="$listeners"
        :checked="checkedW"
      ></el-checkbox>
      <div class="f-1 c-p" @click="openItem">
        <i
          :style="'margin-left:10px;' + (open && 'transform: rotate(180deg);')"
          class="el-icon-arrow-down"
        ></i>
      </div>
    </div>
    <div
      class="content-box o-h d-f w-100_"
      :style="'overflow:hidden;min-height:' + (open ? domHeight : 0) + 'px'"
    >
      <div :style="'height:1px;width:' + (left || 0) + 'px'"></div>
      <div v-if="open" class="f-1" style="flex: 1" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my-collapse",
  data() {
    return {
      open: false,
      domHeight: 0,
      checkedW: false,
    };
  },
  methods: {
    openItem() {
      this.open = !this.open;
      setTimeout(() => {
        this.domHeight = this.$refs.content.scrollHeight;
      }, 10);
    },
  },
  props: {
    left: Number,
    checked: Boolean,
  },
  watch: {
    checked: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val)
        this.checkedW = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.my-collapse {
  .header {
    height: 50px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
  }
  .header + .header {
    border-top: none;
  }
  .content-box {
    transition: all 0.5s;
  }
}
.my-collapse + .my-collapse {
  .header {
    margin-top: -2px;
  }
}
</style>
<style >
.el-icon-arrow-down {
  transition: all 0.5s;
}
</style>

