<template>
  <div class="house-master h-100_">
    <div
      class="room-body h-100_ d-f w-100_"
      style="width: calc(100%); flex-direction: column; margin: 0"
    >
      <manager-header @save="save" />
      <div>
        <div class="room-body-content w-100_" style="height: calc(100% - 24px)">
          <div class="header">
            <span style="margin-left: 30px">管理楼栋</span>
            <span style="margin-left: 160px">管理楼层</span>
            <span style="margin-left: 120px">管理房号</span>
          </div>
          <div class="scroll">
            <my-collapse
              :left="30"
              v-for="item in managerList"
              :key="item.id"
              :label="item.label"
              @change="(val) => houseChange(val, item)"
              :indeterminate="
                (item.children || [[]]).every((it) =>
                  (it.children || []).every((yt) => yt.checked)
                )
                  ? false
                  : (item.children || [[]]).some((it) =>
                      (it.children || []).some((yt) => yt.checked)
                    )
              "
              :value="
                (item.children || [[]]).every((it) =>
                  (it.children || []).every((yt) => yt.checked)
                )
              "
            >
              <my-collapse
                :left="200"
                v-for="ytem in item.children || []"
                :value="getChecked(ytem)"
                :indeterminate="
                  (ytem.children || []).every((it) => it.checked)
                    ? false
                    : (ytem.children || []).some((it) => it.checked)
                "
                :key="ytem.id"
                :label="ytem.label"
                @change="(val) => cellChange(val, ytem)"
              >
                <div
                  style="margin-left: 190px;flex-wrap: wrap;"
                  class="check-box-content d-f ai-c"
                >
                  <el-checkbox
                    style="margin: 10px 10px"
                    v-model="ztem.checked"
                    :label="ztem.label"
                    v-for="ztem in ytem.children || []"
                    :key="ztem.id"
                  />
                </div>
              </my-collapse>
            </my-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("housemaster");
import managerHeader from "./managerHeader.vue";
import MyCollapse from "./myCollapse.vue";
export default {
  name: "house-master",
  components: {
    managerHeader,
    MyCollapse,
  },
  data() {
    return {
      showEdit: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
      buildingNameObject: {},
    };
  },
  methods: {
    ...mapActions([
      "getTableList",
      "getMasterRoomList",
      "getAllRooms",
      "postMasterRoomList",
      "getBuildRoomList",
    ]),
    ...mapMutations(["setSectionItem"]),

    finishEdit(data) {
      //  编辑完成获取数据；
      console.log(data);
    },
    edit(row) {
      // 编辑当前数据
      if (this.chooseType == "cell") {
        //
        this.modifyName = row.cellNo;
        this.modifyVisible = true;
      } else {
        this.showEdit = true;
        console.log(row);
      }
    },
    cellChange(val, item) {
      console.log(val, item);
      item.children.forEach((item) => {
        item.checked = val;
      });
    },
    houseChange(val, item) {
      item.children.forEach((item) => {
        item.children.forEach((ytem) => {
          ytem.checked = val;
        });
      });
    },
    getList(item) {
      if (item.children) {
        return item.children.map((it) => this.getList(it)).filter(Boolean);
      } else {
        return item.checked ? item : false;
      }
    },
    save() {
      const list = this.managerList
        .map((it) => this.getList(it))
        .flat(Infinity)
        .map((it) => ({
          dormId: this.$route.params.id,
          // id: it.id,
          buildingId: it.buildingId,
          // buildingName: it.buildingName,
          roomId: it.id,
          // roomNo: it.roomNo,
          // floorNo: it.floorNo,
        }));
      this.postMasterRoomList({ dormId: this.$route.params.id, list }).then(
        () => {
          this.$router.push("/housemaster");
        }
      );
    },
  },
  mounted() {
    // this.getAllRooms();
    this.getMasterRoomList(this.$route.params.id);
    // this.getBuildRoomList().then((res) => {
    //   res.forEach((item) => {
    //     this.buildingNameObject[item.id] = item.buildingName;
    //   });
    // });
  },
  computed: {
    ...mapState(["tableList", "chooseType", "managerList"]),
    cellsData() {
      console.log(this.chooseType == "cell");
      return this.chooseType == "cell";
    },
    getChecked() {
      return (row) => {
        return row.children.every((it) => it.checked);
      };
    },
  },
  watch: {
    managerList(val) {
      console.log(
        val[0].children.every((it) => (it.children || []).every((yt) => yt.checked))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.house-master {
  background: #f4f5f6;
  padding: 24px;
  .header {
    line-height: 50px;
    font-weight: bold;
  }
  .scroll {
    height: calc(100vh - 250px);
    overflow: auto;
  }
}
</style>
